@import "../../scss";

.ldsDualRing {
  display: inline-block;
  width: 50px;
  height: 50px;
}
.ldsDualRing:after {
  content: " ";
  display: block;
  width: 68%;
  height: 68%;
  margin: 4px;
  border-radius: 50%;
  border: 5px solid $nord10;
  border-color: $nord10 transparent $nord10 transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
