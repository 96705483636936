@import "../../scss";

.range {
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  display: block;
  background-color: $nord4;
  width: 100%;
  height: 6px;
  border-radius: 5px;
}

.range::-webkit-slider-runnable-track {
  -webkit-appearance: none;
  height: 6px;
}
.range::-moz-track {
  -webkit-appearance: none;
  height: 6px;
}
.range::-ms-track {
  -webkit-appearance: none;
  height: 6px;
}

.range::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  margin-top: -9px;
  margin-left: 0;
  background-color: $nord6;
  border: 3px solid $nord10;
  border-radius: 50%;
  height: 24px;
  width: 24px;
}
.range::-moz-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  margin-top: -9px;
  margin-left: 0;
  background-color: $nord6;
  border: 3px solid $nord10;
  border-radius: 50%;
  height: 24px;
  width: 24px;
}
.range::-ms-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  margin-top: -9px;
  margin-left: 0;
  background-color: $nord6;
  border: 3px solid $nord10;
  border-radius: 50%;
  height: 24px;
  width: 24px;
}

.range:hover::-webkit-slider-thumb {
  box-shadow: rgba(144, 202, 249, 0.16) 0px 0px 0px 8px;
}
.range:hover::-moz-range-thumb {
  box-shadow: rgba(144, 202, 249, 0.16) 0px 0px 0px 8px;
}
