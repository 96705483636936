@import "../../scss";

.rangeBox {
  width: 100%;
  padding: 14px;
  display: flex;
  align-items: center;
  gap: 14px;
  background: white;
  border-radius: 8px;
}

.input {
  width: 150px;
  height: 50px;
  text-align: center;
  background: $nord5;
  color: $x-nord1;
  border-radius: 8px;
  border: 1px solid $nord5;
  transition: all 100ms ease-in-out, background 200ms ease-in-out;
  font-size: 1.25rem;
  &:hover {
    border-color: lighten($x-nord1, 25%);
  }
  &:focus {
    background: white;
		border-color: transparent;
    box-shadow: inset 0 0 0 2px $nord10;
  }
}
