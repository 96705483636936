@import "../../scss";

@mixin theme($color) {
  background: darken($color, 7%);
  &::after {
    background: $color;
  }
}

.button {
  cursor: pointer;
  position: relative;
  width: max-content;
  padding: 8px 12px;
  border-radius: 8px;
  transition: 80ms ease-in-out;
  .text {
    display: inline-block;
    z-index: 10;
    position: relative;
    transition: 80ms ease-in-out;
    transform: translateY(-3px);
    color: $nord6;
    .hidden {
      opacity: 0;
    }
  }
  &:hover {
    .text {
      opacity: 0.8;
    }
  }
  &:active {
    .text {
      transform: translateY(0px);
    }
    &::after {
      top: 0;
    }
  }
  &::after {
    content: "";
    left: 0;
    top: -3px;
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 8px;
    transition: 80ms ease-in-out;
  }
}

.blue.button {
  @include theme($nord10);
}

.red.button {
  @include theme($nord11);
}

.orange.button {
  @include theme($nord12);
}

.violet.button {
  @include theme($nord15);
}

.green.button {
  @include theme($nord14);
}

.pending.button {
  @include theme($x-nord1);
  pointer-events: none;
  color: $nord5;
  opacity: 0.6;
  .loading {
    position: absolute;
    top: calc(50% - 2px);
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.blocked.button {
  pointer-events: none;
}
