@import "../../scss";

.item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid $nord4;
    height: 50px;
    .key {
      color: $x-nord1;
      font-size: 1.125rem;
      font-weight: 400;
    }
    .value {
      color: $nord3;
      font-size: 1.125rem;
      font-weight: 400;
    }
    &:last-child {
      border-bottom: none;
    }
  }