@import "../../scss";

.email {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  &.pending {
    pointer-events: none;
  }
}

.container {
  width: 300px;
}

.codeInput {
  width: 100%;
  display: flex !important;
  justify-content: center;
  align-items: center;
  gap: 10px;
  height: 50px;
  border-radius: 12px;
  input {
    height: 100%;
    border: 1px solid $nord4;
    border-radius: 4px;
    transition: 80ms linear;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 0.9375rem;
    color: $nord3;
    width: 16%;
    background: $nord6;
    &:hover {
      border-color: lighten($nord10, 20%);
    }
    &:focus {
      border-color: $nord10;
      background: #fff;
    }
  }
}

.legend {
  color: $x-nord1;
  font-size: 1.25rem;
  padding: 0 11px;
  text-align: center;
}

.pan {
  border-radius: 8px;
  background: white;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
}

.inputs {
  display: flex;
  flex-direction: column;
  gap: 14px;
}

.submit {
  cursor: pointer;
  position: relative;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  color: $nord6;
  background: darken($nord10, 7%);
  .submitText {
    z-index: 10;
    position: relative;
    transition: 80ms ease-in-out;
    transform: translateY(-3px);
    font-weight: 400;
    color: $nord6;
  }
  &:hover {
    &::after {
      background: lighten($nord10, 5%);
    }
  }
  &:active {
    .submitText {
      transform: translateY(0);
    }
    &::after {
      top: 0;
    }
  }
  &::after {
    content: "";
    left: 0;
    top: -3px;
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 8px;
    transition: 80ms ease-in-out;
    background: $nord10;
  }
}

.loading {
  position: absolute;
  top: calc(50% - 4px);
  left: 50%;
  z-index: 100;
  transform: translate(-50%, -50%);
}

.sendAgainButton {
  cursor: pointer;
  color: $nord10;
  font-size: 0.875rem;
  &.disabled {
    color: $x-nord1;
    pointer-events: none;
  }
  &:hover {
    text-decoration: underline;
  }
}
