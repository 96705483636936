@import "../../scss";

.creditDataBox {
    padding: 30px;
    border-radius: 8px;
    background: white;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 10px;
    .title {
      font-size: 1.5rem;
      font-weight: 400;
      color: $x-nord0;
    }
    .list {
      width: 100%;
      display: flex;
      flex-direction: column;
    }
  }